import { Controller } from 'stimulus';

/**
 * Hides and reveals its `fieldset` targets based on conditions
 * they declare through their `data-fieldset-visibility-condition`
 * attribute.
 *
 * These conditions consist of a JSON Hash with:
 * - the `name` of another field in the form
 * - a `value` that that field should have for the field to be revealed. This
 *   can include several value that are space-separated
 *
 * Note: The controller needs to be set on a `<form>` or `<fieldset>`
 * element so it has access to a `.elements` method for finding fields
 */
export default class extends Controller {
  static targets = ['fieldset'];
  static values = { scrollToVisibleFieldset: Boolean };

  connect() {
    this.fieldsetTargets.forEach((fieldsetElement) => {
      const condition = JSON.parse(
        fieldsetElement.getAttribute('data-fieldset-visibility-condition')
      );

      const updateVisibility = (value) => {
        if (conditionValueMet(value, condition.value)) {
          fieldsetElement.disabled = false;
          fieldsetElement.classList.remove('d-none');

          if (this.scrollToVisibleFieldsetValue) {
            fieldsetElement.scrollIntoView({ behavior: 'smooth' });
          }
        } else {
          fieldsetElement.disabled = true;
          fieldsetElement.classList.add('d-none');
        }
      };

      const value = this.element.elements[condition.name].value;
      updateVisibility(value);

      this.element.addEventListener('change', ({ target }) => {
        if (target.name == condition.name) {
          // This allows to obtain a RadioNodeList for radio inputs
          // which makes reading their value that much easier
          const value = target.form.elements[target.name].value;
          updateVisibility(value);
        }
      });
    });
  }
}

// if the actual_value is blank, match only with another blank - otherwise we can use includes
function conditionValueMet(actual_value, condition_value) {
  if (typeof actual_value === 'string' && actual_value.length === 0) {
    return typeof condition_value === 'string' && condition_value.length === 0;
  } else {
    return condition_value.includes(actual_value);
  }
}
