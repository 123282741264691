import Dropdown from 'bootstrap/js/src/dropdown';
import Modal from 'bootstrap/js/src/modal';
import Offcanvas from 'bootstrap/js/src/offcanvas';
import Tooltip from 'bootstrap/js/src/tooltip';

/**
 * To make sure Bootstrap components get restored properly
 * when Turbo loads a page from cache, we need to clean up
 * Bootstrap's JavaScript components properly
 */

document.addEventListener('turbo:before-cache', function () {
  hideOpenModalAndOffcanvas();
  hideOpenDropdowns();
  hideVisibleTooltips();
});

// Modals & Offcanvas'

function hideOpenModalAndOffcanvas() {
  // Bootstrap guarantees only one of each element will be open so we can just `querySelector`
  const openElements = [
    document.querySelector('.modal.show'),
    document.querySelector('.offcanvas.show'),
  ].filter((el) => el);

  if (openElements) {
    openElements.forEach((el) => {
      let instance;

      if (el.classList.contains('modal')) {
        instance = Modal.getInstance(el);
      } else if (el.classList.contains('offcanvas')) {
        instance = Offcanvas.getInstance(el);
      }

      if (instance) {
        // Removing `fade` forces the element to hide instantly,
        // meaning it'll be properly hidden before Turbo actually caches the page
        // https://github.com/twbs/bootstrap/blob/edf9c40956d19e6ab3f9151bfe0dfac6be06fa21/js/src/modal.js#L256
        el.classList.remove('fade');

        // When navigating back & forth a backdrop will remain as the navigation is
        // too fast for the transition, so removing it manually. Bootstrap creates it
        // each time these elements are shown.
        document.querySelector('.offcanvas-backdrop')?.remove();
        document.querySelector('.modal-backdrop')?.remove();

        // Hide the element itself
        instance.hide();
        // Restore `fade` to ensure transitions happen as intended when Turbo restores the page
        el.classList.add('fade');
      }
    });
  }
}

// Dropdowns

function hideOpenDropdowns() {
  const openDropdownElements = document.querySelectorAll(
    '.dropdown-toggle.show'
  );
  for (const openDropdownElement of openDropdownElements) {
    hideOpenDropdown(openDropdownElement);
  }
}

function hideOpenDropdown(element) {
  const dropdown = Dropdown.getInstance(element);
  if (dropdown) {
    dropdown.hide();
  }
}

// Tooltips

function hideVisibleTooltips() {
  const visibleTooltipElements = document.querySelectorAll('.tooltip');
  for (const visibleTooltipElement of visibleTooltipElements) {
    hideVisibleTooltip(visibleTooltipElement);
  }
}

function hideVisibleTooltip(element) {
  const toggle = document.querySelector(`[aria-describedby="${element.id}"]`);
  const instance = Tooltip.getInstance(toggle);
  // There seems to be click timings where there'd be no instance
  if (instance) {
    element.classList.remove('fade');
    instance.hide();
    element.classList.add('fade');
  }
}
