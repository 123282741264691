import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['outcome', 'tickbox', 'icon'];

  toggleOutcomeClass(e) {
    this.outcomeTargets.forEach((element) => {
      element.classList.remove('amba-notes-form-outcome--active');
    });

    e.target.classList.toggle('amba-notes-form-outcome--active');
  }

  toggleTickboxClass(e) {
    const clickedLabel = e.currentTarget;
    const checkbox = clickedLabel.querySelector('input[type="checkbox"]');
    const tickbox = clickedLabel.querySelector(
      '[data-notes-form-target="tickbox"]'
    );
    const icon = clickedLabel.querySelector('[data-notes-form-target="icon"]');

    if (checkbox && tickbox && icon) {
      // Toggle classes based on checkbox state
      tickbox.classList.toggle(
        'amba-notes-form-tickbox--active',
        checkbox.checked
      );
      icon.classList.toggle(
        'amba-notes-form-tickbox-icon--active',
        checkbox.checked
      );
    }
  }
}
