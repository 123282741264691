import { Controller } from 'stimulus';

export default class extends Controller {
  static values = {
    controlTarget: String,
  };
  static targets = ['parentSection', 'elementToDisplay', 'controlElement'];

  connect() {
    this.conditionallyDisplayElement();

    const observer = new MutationObserver(() => {
      this.conditionallyDisplayElement();
    });

    observer.observe(this.parentSectionTarget, {
      childList: true,
      tree: true,
    });
  }

  conditionallyDisplayElement() {
    let shouldDisplayElement = false;

    this.controlElementTargets.every((controlElement) => {
      if (
        controlElement.dataset.observerElementValue === this.controlTargetValue
      ) {
        this.elementToDisplayTarget.classList.remove('d-none');
        shouldDisplayElement = true;
        return false;
      } else {
        return true;
      }
    });

    if (!shouldDisplayElement && this.elementToDisplayTarget !== 'undefined') {
      this.elementToDisplayTarget.classList.add('d-none');
    }
  }
}
