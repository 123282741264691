import { Controller } from 'stimulus';

/**
 * Allow a frame to auto reload every X seconds to the provided url.
 *
 * When reloading a frame, `frameElement.reload();` won't work when pointing to the current
 * url due to caching restrictions. This controller uses the older method of setting the
 * `src` attribute to load the frame content.
 *
 * It'll also pause the timer when a modal or offcanvas is open and update the params
 * if a child collapse element is expanded or collapsed.
 */
export default class extends Controller {
  static values = {
    url: String,
    secondsToReload: Number,
  };

  connect() {
    this.startTimer();
    this.addListeners();
  }

  disconnect() {
    clearTimeout(this.timer);
    this.removeListeners();
  }

  startTimer = () => {
    this.timer = setTimeout(() => {
      this.reloadFrameToUrl();
      this.startTimer();
    }, this.msToReload);
  };

  clearTimer = () => {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  };

  reloadFrameToUrl() {
    this.element.src = null;
    this.element.src = this.urlValue;
  }

  addListeners() {
    this.element.addEventListener(
      'shown.bs.collapse',
      this.updateUrlParamsForCollapse
    );
    this.element.addEventListener(
      'hidden.bs.collapse',
      this.updateUrlParamsForCollapse
    );

    ['shown.bs.modal', 'shown.bs.offcanvas'].forEach((listener) => {
      document.addEventListener(listener, this.clearTimer);
    });

    ['hidden.bs.modal', 'hidden.bs.offcanvas'].forEach((listener) => {
      document.addEventListener(listener, this.startTimer);
    });
  }

  removeListeners() {
    this.element.removeEventListener(
      'shown.bs.collapse',
      this.updateUrlParamsForCollapse
    );
    this.element.removeEventListener(
      'hidden.bs.collapse',
      this.updateUrlParamsForCollapse
    );

    ['shown.bs.modal', 'shown.bs.offcanvas'].forEach((listener) => {
      document.removeEventListener(listener, this.clearTimer);
    });

    ['hidden.bs.modal', 'hidden.bs.offcanvas'].forEach((listener) => {
      document.removeEventListener(listener, this.startTimer);
    });
  }

  updateUrlParamsForCollapse = (event) => {
    const isExpanded = event.type.includes('shown');

    const url = new URL(this.urlValue);

    url.searchParams.set('collapse_expanded', isExpanded);

    this.urlValue = url;
  };

  get msToReload() {
    return this.secondsToReloadValue * 1000;
  }
}
