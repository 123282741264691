// app/javascript/controllers/remote_controller.js
import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.element.addEventListener(
      'turbo:submit-success',
      this.reloadPage.bind(this)
    );
  }

  reloadPage() {
    window.location.reload();
  }
}
