import { Controller } from 'stimulus';
import Dropdown from 'bootstrap/js/src/dropdown';

/**
 * The vip feed table has sticky day headers which include an actions dropdown.
 * If this dropdown is open and the page is scrolled, the dropdown stays open underneath the
 * following sticky header.
 *
 * This controller detects this collision and hides the dropdown.
 * This happens both when scrolling, and when the dropdown is shown.
 */
export default class extends Controller {
  static targets = ['dropdown'];

  connect() {
    if (!this.hasDropdownTarget) return;

    this.dropdownTarget.addEventListener(
      'shown.bs.dropdown',
      this.detectCollision
    );
    this.dropdownTarget.removeEventListener(
      'hidden.bs.dropdown',
      this.detectCollision
    );

    // Tidy up all scroll listeners when the dropdown is hidden
    this.dropdownTarget.addEventListener('hidden.bs.dropdown', () => {
      window.removeEventListener('scroll', this.scrollHeightCallback);
      window.removeEventListener('scroll', this.detectCollisionOnScroll);
    });
  }

  detectCollision = async () => {
    // First wait for scrolling the user up if there's a collision...
    await this.detectCollisionOnShow();

    // ...then attach the scroll listener
    window.addEventListener('scroll', this.detectCollisionOnScroll);
  };

  detectCollisionOnShow() {
    // If the dropdown collides with the sticky header, scroll the user up and resolve
    // when this is finished.
    // If there's no collision, resolve straight away
    return new Promise((resolve) => {
      const [dropdownBottom, bodyBottom] = this.dropdownAndBodyPositions;

      if (dropdownBottom >= bodyBottom) {
        const offset = dropdownBottom - bodyBottom;

        const scrollYTarget = window.scrollY - offset;

        this.scrollHeightCallback = () => {
          if (window.scrollY <= scrollYTarget) {
            window.removeEventListener('scroll', this.scrollHeightCallback);
            resolve();
          }
        };

        window.addEventListener('scroll', this.scrollHeightCallback);
        window.scrollTo({ top: scrollYTarget, behavior: 'smooth' });
      } else {
        resolve();
      }
    });
  }

  detectCollisionOnScroll = () => {
    const [dropdownBottom, bodyBottom] = this.dropdownAndBodyPositions;

    if (dropdownBottom >= bodyBottom) {
      this.hideDropdown();
    }
  };

  hideDropdown() {
    window.removeEventListener('scroll', this.detectCollisionOnScroll);

    const dropdownToggle =
      this.dropdownTarget.querySelector('.dropdown-toggle');
    Dropdown.getInstance(dropdownToggle).hide();
  }

  get dropdownAndBodyPositions() {
    const dropdownBottom = this.dropdownMenu.getBoundingClientRect().bottom;
    const bodyBottom = this.element.getBoundingClientRect().bottom;

    return [dropdownBottom, bodyBottom];
  }

  get dropdownMenu() {
    return this.dropdownTarget.querySelector('.dropdown-menu');
  }
}
