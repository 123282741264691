import { Controller } from 'stimulus';
import useBootstrapSelect from 'use-bootstrap-select';

export default class extends Controller {
  static values = {
    options: Array,
  };

  connect() {
    new useBootstrapSelect(this.element, {
      title: 'Select options',
      liveSearch: true,
      searchable: true,
      data: this.optionsValue,
    });
  }

  disconnect() {
    if (this.bootstrapSelect) {
      this.bootstrapSelect.destroy();
    }
  }
}
