import { Controller } from 'stimulus';

const MOBILE_BREAKPOINT = 992;

export default class extends Controller {
  static values = {
    enabled: Boolean, // enabled is true when the organisation has RPM monitoring enabled
  };

  connect() {
    if (!this.shouldInitializeDrawer()) {
      this.close();
      return;
    }

    this.initializeDrawerState();
    this.handleMobileDrawer();
  }

  shouldInitializeDrawer() {
    // Only initialize the drawer if the drawer element exists on the page
    // and the drawer is enabled
    const drawer = document.getElementById('amba-drawer-content');
    return drawer && this.enabledValue;
  }

  initializeDrawerState() {
    const savedState = localStorage.getItem('drawer');
    this.element.checked = savedState !== 'closed';

    if (savedState === null) {
      localStorage.setItem('drawer', 'open');
    }
  }

  handleMobileDrawer() {
    if (window.innerWidth < MOBILE_BREAKPOINT) {
      this.close();
      localStorage.setItem('drawer', 'closed');
    }
  }

  close() {
    this.element.checked = false;
  }

  saveState() {
    if (window.location.href.includes('analytics')) {
      // Emit an event when drawer state changes so we can programmatically
      // resize the chart in the chart_controller.js. This is necessary
      // to fix a layout issue where the chart doesn't resize properly
      // when the drawer is toggled.
      window.dispatchEvent(new Event('drawer:toggled'));
    }

    // store the state of the drawer in local storage
    this.element.checked
      ? localStorage.setItem('drawer', 'open')
      : localStorage.setItem('drawer', 'closed');
  }
}
