import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['parentSection', 'countElement', 'countDisplay'];

  connect() {
    const observer = new MutationObserver(() => {
      this.updateCount();
    });

    observer.observe(this.parentSectionTarget, {
      childList: true,
      tree: true,
    });
  }

  updateCount() {
    this.countDisplayTarget.textContent = this.countElementTargets.length;
  }
}
