import { Controller } from 'stimulus';
import SlimSelect from 'slim-select';

export default class extends Controller {
  static values = {
    options: Array,
  };

  connect() {
    this.initializeSlimSelect();
  }

  disconnect() {
    if (this.slimSelect) {
      this.slimSelect.destroy();
    }
  }

  initializeSlimSelect() {
    this.slimSelect = new SlimSelect({
      select: this.element,
      data: this.optionsValue,
      settings: {
        showSearch: true,
        placeholderText: 'Select data points',
        allowDeselect: false,
        closeOnSelect: false,
      },
    });
  }
}
