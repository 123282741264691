import { Controller } from 'stimulus';

/**
 * The task list dashboard page has new alerts & alert intentions streamed to the page.
 * The page can be also be filtered by floors.
 *
 * This controller intercepts streams and conditionally renders them in line with the
 * floor filters that are applied
 */
export default class extends Controller {
  static values = {
    floorIds: Array,
  };

  connect() {
    document.addEventListener(
      'turbo:before-stream-render',
      this.interceptStream
    );
  }

  disconnect() {
    document.removeEventListener(
      'turbo:before-stream-render',
      this.interceptStream
    );
  }

  interceptStream = ({ detail }) => {
    // Access the content within the stream <template>
    const streamContent = detail.newStream.firstChild.content;

    if (!streamContent) return;

    const relevantStreamElement = streamContent.querySelector(
      '[data-task-list-dashboard="true"]'
    );

    if (!relevantStreamElement) return;

    const streamFloorId = relevantStreamElement.getAttribute(
      'data-task-list-dashboard-floor-id'
    );

    if (!streamFloorId) return;

    if (
      this.floorIdsValue.length == 0 ||
      this.floorIdsValue.includes(Number(streamFloorId))
    ) {
      // Do nothing
      return;
    } else {
      // Prevent stream rendering
      detail.render = () => {};
    }
  };
}
