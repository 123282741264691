import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['radioButtons', 'fieldset'];

  connect() {
    this.errorMessage = document.createElement('p');
    this.errorMessage.classList.add('invalid-feedback');
    this.errorMessage.textContent = 'Please select an option.';

    this.radioButtonsTargets.forEach((radio) => {
      radio.addEventListener('change', this.clearValidationError.bind(this));
    });
  }

  validate(event) {
    if (this.radioButtonsTargets.every((radio) => !radio.checked)) {
      event.preventDefault();
      this.showValidationError();
      this.addInvalidClass();
    }
  }

  showValidationError() {
    if (
      this.fieldsetTarget &&
      !this.fieldsetTarget.contains(this.errorMessage)
    ) {
      this.fieldsetTarget.appendChild(this.errorMessage);
    }
  }

  addInvalidClass() {
    this.radioButtonsTargets.forEach((radio) => {
      radio.classList.add('is-invalid');
    });
  }

  clearValidationError() {
    if (
      this.fieldsetTarget &&
      this.fieldsetTarget.contains(this.errorMessage)
    ) {
      this.fieldsetTarget.removeChild(this.errorMessage);
    }

    this.radioButtonsTargets.forEach((radio) => {
      radio.classList.remove('is-invalid');
    });
  }
}
