import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['confirmationLink'];

  static values = {
    formSubmitted: Boolean,
    completed: Boolean,
  };

  connect() {
    if (this.completedValue) {
      return;
    }

    this.isInternalNavigation = false;

    // Any internal link clicks will be intercepted by this event listener
    document.addEventListener(
      'turbo:before-visit',
      this.handleBeforeTurboVisit
    );

    // Any external navigation will be intercepted by this event listenter
    // and warn the user if they have unsaved changes i.e. closing the tab,
    // hard refresh, etc.
    window.addEventListener('beforeunload', this.handleBeforeUnload);
  }

  handleBeforeTurboVisit = (event) => {
    const sourceElement = document.activeElement;
    const skipRpmConfirmation = sourceElement.hasAttribute(
      'data-skip-rpm-confirmation'
    );
    const url = new URL(event.detail.url);
    const override = url.searchParams.get('force_leave') === 'true';

    if (!this.formSubmittedValue && !override && !skipRpmConfirmation) {
      this.isInternalNavigation = true;
      event.preventDefault();

      // Clicking the confirmation link target triggers the exit popup
      // when there are unsaved changes and confirms the user wants to leave
      // without logging rpm time

      const link = this.confirmationLinkTarget;
      const originalHref = new URL(link.href);

      originalHref.searchParams.set('destination_url', event.detail.url);
      link.href = originalHref.toString();
      link.click();
    }
  };

  handleBeforeUnload = (event) => {
    // Sometimes this fires after a turbo click, so if we're navigating internally
    // we don't want to show the warning as we'll handle it in the `turbo:before-visit` event.
    // Hence the use of `isInternalNavigation` to prevent the warning from showing.
    const sourceElement = document.activeElement;
    const skipRpmConfirmation = sourceElement.hasAttribute(
      'data-skip-rpm-confirmation'
    );

    if (
      !this.formSubmittedValue &&
      !this.isInternalNavigation &&
      !skipRpmConfirmation
    ) {
      event.preventDefault();
      event.returnValue =
        'You have unsaved assessment changes. Are you sure you want to leave?';
      return event.returnValue;
    }
    this.isInternalNavigation = false;
  };

  disconnect() {
    document.removeEventListener(
      'turbo:before-visit',
      this.handleBeforeTurboVisit
    );
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  }

  submitRpmTime() {
    const rpmTimeInputs = document.querySelectorAll(
      '#form_objects_rpm_assessment_form_rpm_time'
    );
    const rpmStorageObject = JSON.parse(
      localStorage.getItem('rpm-session-time')
    );

    rpmTimeInputs.forEach((input) => {
      input.value = rpmStorageObject.time;
    });

    this.formSubmittedValue = true;
    window.dispatchEvent(new Event('rpmTimeSubmitted'));
  }
}
